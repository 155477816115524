import React from 'react'

type IconUserProps = {
  color?: string
}

const IconUser: React.FC<IconUserProps> = ({ color = '#636571' }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00016 0.333008C5.1585 0.333008 3.66683 1.82467 3.66683 3.66634C3.66683 5.50801 5.1585 6.99967 7.00016 6.99967C8.84183 6.99967 10.3335 5.50801 10.3335 3.66634C10.3335 1.82467 8.84183 0.333008 7.00016 0.333008ZM8.66683 3.66667C8.66683 2.75 7.91683 2 7.00016 2C6.0835 2 5.3335 2.75 5.3335 3.66667C5.3335 4.58333 6.0835 5.33333 7.00016 5.33333C7.91683 5.33333 8.66683 4.58333 8.66683 3.66667ZM12.0002 11.9997C11.8335 11.408 9.25016 10.333 7.00016 10.333C4.7585 10.333 2.19183 11.3997 2.00016 11.9997H12.0002ZM0.333496 11.9993C0.333496 9.78268 4.77516 8.66602 7.00016 8.66602C9.22516 8.66602 13.6668 9.78268 13.6668 11.9993V13.666H0.333496V11.9993Z"
      fill={color}
    />
  </svg>
)

export default IconUser
