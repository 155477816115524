import React from 'react'

import classes from './Button.module.scss'

type TButton = {
  onClick: (event?: React.MouseEvent<HTMLElement>) => void
  children: React.ReactNode
  isActive?: boolean
  styles?: React.CSSProperties
}

export default function Button({ onClick, children, isActive, styles }: TButton) {
  let rootClasses = classes.root

  if (isActive) {
    rootClasses += ` ${classes.root_active}`
  }
  return (
    <button
      type="button"
      onClick={onClick}
      className={rootClasses}
      style={styles ? { ...styles } : {}}
    >
      {children}
    </button>
  )
}
