import React, { ElementType } from 'react'
import Link from 'next/link'

import classes from './Button.module.scss'

type TButton = {
  text: string
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void
  Icon?: ElementType
  theme?: 'primary' | 'secondary' | 'contrast' | 'transparent'
  disabled?: boolean
  rotateIcon?: boolean
  active?: boolean
  type?: 'button' | 'submit' | 'link'
  href?: string
  styles?: React.CSSProperties
}

const Button: React.FC<TButton> = ({
  text,
  onClick,
  Icon,
  theme = 'primary',
  disabled = false,
  active = false,
  rotateIcon = false,
  type = 'button',
  styles,
  href,
}) => {
  let rootClasses = classes.root
  let iconClasses = classes.icon

  if (theme === 'primary') {
    rootClasses += ` ${classes.root_primary}`
  }

  if (theme === 'secondary') {
    rootClasses += ` ${classes.root_secondary}`
  }

  if (theme === 'contrast') {
    rootClasses += ` ${classes.root_contrast}`
  }

  if (theme === 'transparent') {
    rootClasses += ` ${classes.root_transparent}`
  }

  if (active) {
    rootClasses += ` ${classes.root_active}`
  }

  if (rotateIcon) {
    iconClasses += ` ${classes.icon_flipped}`
  }

  if (type === 'link' && disabled) {
    rootClasses += ` ${classes.root_disabled}`
  }

  if (type !== 'link') {
    return (
      <button
        type={type}
        className={rootClasses}
        onClick={onClick}
        disabled={disabled}
        style={styles || {}}
      >
        {text}
        {Icon && <Icon className={iconClasses} />}
      </button>
    )
  }

  return (
    <Link
      className={rootClasses}
      href={href || ''}
      style={styles || {}}
      replace={true}
      prefetch={false}
      passHref
      draggable="false"
      onClick={onClick}
    >
      {text}
      {Icon && <Icon className={iconClasses} />}
    </Link>
  )
}

export default Button
